import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import {
  Avatar,
  ButtonCardWithAvatar,
  ContentContainerAppClient,
  FlexColumn,
  LegacyDialog,
  RowButtonV2,
  RowContainerV2,
  Text,
  borderRadius,
  colors,
  downloadFile,
  mobileMediaQuery,
  mobileSideSpace,
  primaryColor,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
  useLanguage,
  useScreenType,
  useTranslate,
} from '@orus.eu/pharaoh'
import { OrusHorizontalLogoImage } from '@orus.eu/pharaoh/src/skin/default-skin/orus-horizontal-logo-image'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback, useState } from 'react'
import { trpc } from '../../client'
import { dynamicFileNames } from '../../lib/download-filenames'
import { getMyCertificateDownloadLink } from '../../lib/download-links'
import { getMutaSituation } from '../../lib/muta-util'
import { useWindowLocationHash } from '../../lib/use-window-location-hash'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { ContractDocumentDownloadRow } from '../organisms/contract-documents-download-row'
import { Loader } from '../organisms/loader'
import { WelcomeDialog } from '../organisms/welcome-dialog/welcome-dialog'
import { SmallScreenMargin } from '../templates/small-screen-margin'

export function CustomerHomePage(): JSX.Element {
  const screenType = useScreenType()
  return (
    <div>
      {screenType === 'mobile' ? (
        <div
          css={css`
            padding: ${spacing[50]} ${spacing[60]};
          `}
        >
          <OrusHorizontalLogoImage width={56} height={19} color={primaryColor} />
        </div>
      ) : null}

      <Loader apiUsage={loadHomePageData} element={(data) => <HomePageContentBlock data={data} />} />
    </div>
  )
}

export default CustomerHomePage

async function loadHomePageData(): Promise<HomePageData> {
  const [userAccount, contracts] = await Promise.all([
    trpc.users.loadMyInformation.query(),
    trpc.contracts.listMyContracts.query(),
  ])
  return { userAccount, contracts }
}

type HomePageData = {
  userAccount: UserAccount
  contracts: CustomerContractDescription[]
}

type HomePageContentBlockProps = {
  data: HomePageData | null
}

function HomePageContentBlock({ data }: HomePageContentBlockProps): JSX.Element {
  const translate = useTranslate()
  const hash = useWindowLocationHash()
  const navigate = useNavigate()
  if (data === null) {
    // This is an edge case where the connected user has been deleted from the database.
    // Possible causes : test environment reset, GDPR requests, etc...
    // Handle by logging out
    trpc.sessions.logout.mutate(undefined, { context: { requiresResponseHeadersOrBuffers: true } }).then(
      () => document.location.reload(),
      (err: unknown) => {
        throw err
      },
    )
    return <GlobalLoadingState />
  }

  const { contracts, userAccount } = data

  const welcomeModalOpen = hash === 'onboarding'

  const onClose = () => {
    void navigate({ hash: '', replace: true })
  }

  if (contracts.length === 0) {
    // Should only happen for Orus agents (customers have to go through the subscription process)
    return (
      <>
        <Typography variant="body2" component="p" sx={{ margin: `${spacing[60]} ${mobileSideSpace}` }}>
          {translate('welcome_no_contracts')}
        </Typography>
      </>
    )
  }

  const customerEmail = 'email' in userAccount ? userAccount.email : userAccount.unverifiedEmail
  return (
    <ContentContainerAppClient gap="0">
      <QuickAccessBlock userAccount={userAccount} contracts={contracts} customerEmail={customerEmail} />
      {welcomeModalOpen ? (
        <WelcomeDialog onClose={onClose} contracts={contracts} customerEmail={customerEmail} />
      ) : (
        <></>
      )}
    </ContentContainerAppClient>
  )
}

type QuickAccessBlockProps = {
  userAccount: UserAccount
  contracts: CustomerContractDescription[]
  customerEmail: string
}

function QuickAccessBlock({ userAccount, contracts, customerEmail }: QuickAccessBlockProps): JSX.Element {
  const translate = useTranslate()
  const { isMuta, isMutaOnly } = getMutaSituation(contracts)

  const navigate = useNavigate()

  const onContractClick = useCallback(() => {
    void navigate({ to: '/contracts' })
  }, [navigate])
  const onHealthInsuranceClick = useCallback(() => {
    void navigate({ to: '/health-insurance' })
  }, [navigate])
  const onGuaranteesClick = useCallback(() => {
    void navigate({ to: '/contract-guarantees' })
  }, [navigate])
  const onInvoiceClick = useCallback(() => {
    void navigate({ to: '/invoices' })
  }, [navigate])
  const onReportClaimClick = useCallback(() => {
    void navigate({ to: '/report-claim' })
  }, [navigate])

  return (
    <SmallScreenMargin>
      <Typography variant="h2" sx={{ paddingTop: spacing[60] }}>
        <WelcomeSentence userAccount={userAccount} />
      </Typography>

      <Text
        css={css`
          margin-top: ${spacing[70]};
        `}
        variant="subtitle2"
      >
        {translate('quick_access')}
      </Text>

      <div
        css={css`
          margin-top: ${spacing[50]};
          display: ${isMutaOnly ? undefined : 'grid'};
          grid-template-columns: ${isMutaOnly ? undefined : 'repeat(2, minmax(0, 1fr))'};
          justify-content: center;
          gap: ${spacing[30]};
        `}
      >
        <DownloadMyCertificateButton contracts={contracts} customerEmail={customerEmail} />
        {isMutaOnly ? undefined : (
          <ButtonCardWithAvatar
            text={translate('report_claim')}
            avatarColor={colors.peach.gradient}
            avatarIcon="bell-light"
            onClick={onReportClaimClick}
          />
        )}
      </div>

      <RowContainerV2
        css={css`
          margin-top: ${spacing[70]};
          border: none;
          background-color: ${colors.linen};
        `}
      >
        <RowButtonV2
          primaryText={translate('my_contracts')}
          avatarLeft={<Avatar icon="folder-solid" color={colors.blue[500]} size="30" />}
          onClick={onContractClick}
        />
        {isMuta ? (
          <RowButtonV2
            primaryText={translate('my_health_insurance')}
            avatarLeft={<Avatar icon="heart-solid" color={colors.blue[500]} size="30" />}
            onClick={onHealthInsuranceClick}
          />
        ) : undefined}
        <RowButtonV2
          primaryText={translate('my_guarantees')}
          avatarLeft={<Avatar icon="umbrella-solid" color={colors.blue[500]} size="30" />}
          onClick={onGuaranteesClick}
        />
        <RowButtonV2
          primaryText={translate('my_invoices')}
          avatarLeft={<Avatar icon="receipt-solid" color={colors.blue[500]} size="30" />}
          onClick={onInvoiceClick}
        />
      </RowContainerV2>
    </SmallScreenMargin>
  )
}

type DownloadMyCertificateButtonProps = {
  contracts: CustomerContractDescription[]
  customerEmail: string
}

function DownloadMyCertificateButton({ contracts, customerEmail }: DownloadMyCertificateButtonProps): JSX.Element {
  const translate = useTranslate()
  const language = useLanguage()
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)
  const {
    hide: hideContractSelectionDialog,
    show: showContractSelectionDialog,
    visible: isContractSelectionDialogVisible,
  } = useDialogVisibility('select-contract')

  const downloadContractCertificate = useAsyncCallback(
    async (contract: CustomerContractDescription) => {
      const downloadCertificateLink = getMyCertificateDownloadLink(contract.subscriptionId)
      const protectionStatus = contract.currentProtectionStatus

      if (protectionStatus === 'suspended') {
        alert(translate('your_contract_is_suspended'))
        return
      }

      if (protectionStatus === 'terminated') {
        alert(translate('your_contract_is_terminated'))
        return
      }

      try {
        setDownloadInProgress(true)
        await downloadFile(downloadCertificateLink, dynamicFileNames['insurance-certificate'](customerEmail, language))
      } finally {
        setDownloadInProgress(false)
      }
    },
    [customerEmail, language, translate],
  )

  const handleDownloadClick = useCallback(() => {
    if (contracts.length === 1) {
      downloadContractCertificate(contracts[0])
      return
    }

    showContractSelectionDialog()
  }, [contracts, downloadContractCertificate, showContractSelectionDialog])

  return (
    <>
      <ButtonCardWithAvatar
        text={translate(contracts.length === 1 ? 'download_my_certificate' : 'download_my_certificates')}
        onClick={handleDownloadClick}
        disabled={downloadInProgress}
        downloadInProgress={downloadInProgress}
        avatarColor={colors.periwinkle.gradient}
        avatarIcon="arrow-down-to-line-light"
      />
      {isContractSelectionDialogVisible ? (
        <ContractSelectionDialog contracts={contracts} onClose={hideContractSelectionDialog} />
      ) : (
        <></>
      )}
    </>
  )
}

type WelcomeSentenceProps = {
  userAccount: UserAccount
}

function WelcomeSentence({ userAccount }: WelcomeSentenceProps): JSX.Element {
  const translate = useTranslate()
  if (userAccount.firstName) {
    return (
      <>
        <Text variant="h3">{translate('customer_app_welcome_title', { firstName: userAccount.firstName })}</Text>
      </>
    )
  }

  return <>{translate('welcome_to_orus')}</>
}

type ContractSelectionDialogProps = {
  contracts: CustomerContractDescription[]
  onClose: () => void
}
const ContractSelectionDialog = memo<ContractSelectionDialogProps>(function ContractSelectionDialog({
  contracts,
  onClose,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  return (
    <LegacyDialog header={<Text variant="subtitle1">{translate('download_your_certificates')}</Text>} onClose={onClose}>
      <DocumentCard>
        {contracts
          .filter((contract) => ['active', 'not-started'].includes(contract.currentProtectionStatus))
          .map((contract) => ({
            method: 'get' as const,
            fileName: dynamicFileNames['insurance-certificate'](contract.contractNumber, language),
            url: getMyCertificateDownloadLink(contract.subscriptionId),
            subscriptionId: contract.subscriptionId,
          }))
          .map(({ subscriptionId, ...contract }) => (
            <ContractDocumentDownloadRow key={subscriptionId} doc={contract} />
          ))}
      </DocumentCard>
    </LegacyDialog>
  )
})

const DocumentCard = styled(FlexColumn)`
  gap: 0;
  padding: 0;
  border: 1px ${colors.gray[200]} solid;
  border-radius: ${borderRadius[30]};
  background-color: ${colors.white};
  ${mobileMediaQuery} {
    gap: 0;
    padding: 0;
    background-color: ${colors.white};
  }
`
