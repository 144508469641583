import {
  Avatar,
  Button,
  CustomModal,
  DownloadButtonWithHref,
  FlexSpacedColumn,
  LegacyDialog,
  Text,
  colors,
  spacing,
  useLanguage,
  useScreenVariant,
  useTranslate,
  type ScreenVariant,
} from '@orus.eu/pharaoh'
import { dynamicFileNames } from '../../../lib/download-filenames'
import { getMyCertificateDownloadLink } from '../../../lib/download-links'

import styled from '@emotion/styled'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import check from '@orus.eu/pharaoh/src/components/organisms/custom-modal/check.png'
import comeDesktop from '@orus.eu/pharaoh/src/components/organisms/custom-modal/come.png'
import comeDesktopEs from '@orus.eu/pharaoh/src/components/organisms/custom-modal/comeEs.png'
import comeMobile from '@orus.eu/pharaoh/src/components/organisms/custom-modal/comeMobile.png'
import comeMobileEs from '@orus.eu/pharaoh/src/components/organisms/custom-modal/comeMobileEs.png'
import house from '@orus.eu/pharaoh/src/components/organisms/custom-modal/house.png'
import type { Language } from '@orus.eu/translations'
import { memo } from 'react'

const urlByLanguageByScreenType: Record<Language, Record<ScreenVariant, string>> = {
  fr: {
    desktop: comeDesktop,
    mobile: comeMobile,
    backoffice: comeDesktop,
  },
  es: {
    desktop: comeDesktopEs,
    backoffice: comeDesktopEs,
    mobile: comeMobileEs,
  },
}

type WelcomeDialogProps = {
  onClose: () => void
  contracts: CustomerContractDescription[]
  customerEmail: string
}
export const WelcomeDialog = memo<WelcomeDialogProps>(function WelcomeDialog({ onClose, contracts, customerEmail }) {
  return contracts.length === 1 ? (
    <FirstTimeWelcomeDialog onClose={onClose} contract={contracts[0]} customerEmail={customerEmail} />
  ) : (
    <SubsequentWelcomeDialog onClose={onClose} />
  )
})

type FirstTimeWelcomeDialogProps = {
  onClose: () => void
  contract: CustomerContractDescription
  customerEmail: string
}

const FirstTimeWelcomeDialog = memo<FirstTimeWelcomeDialogProps>(function WelcomeDialog({
  onClose,
  contract,
  customerEmail,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  const downloadCertificateLink = getMyCertificateDownloadLink(contract.subscriptionId)
  const screenType = useScreenVariant()

  return (
    <CustomModal
      title={translate('welcome_to_orus')}
      firstBlockAvatar={check}
      firstBlockTitle={translate('contract_validated')}
      firstBlockButtonText={translate('download_my_certificate')}
      firstBlockIsButton
      firstBlockText={translate('contract_validated_explanation')}
      secondBlockAvatar={house}
      secondBlockTitle={translate('all_in_one_space')}
      secondBlockText={translate('all_in_one_space_explanation')}
      imageSrcDesktop={urlByLanguageByScreenType[language][screenType]}
      imageSrcMobile={urlByLanguageByScreenType[language][screenType]}
      isPrimaryButton
      primaryButtonIcon="arrow-right-regular"
      primaryButtonText={translate('open_my_personal_space')}
      primaryButtonVariant="primary"
      primaryButtonAction={onClose}
      onClose={onClose}
    >
      <DownloadButtonWithHref
        variant="secondary"
        size={screenType === 'mobile' ? 'large' : 'medium'}
        href={downloadCertificateLink}
        fullWidth
        fileName={dynamicFileNames['insurance-certificate'](customerEmail, language)}
        displayName={translate('download_my_certificate')}
      />
    </CustomModal>
  )
})

const SubsequentWelcomeDialog = memo<{ onClose: () => void }>(function SubsequentWelcomeDialog({ onClose }) {
  const translate = useTranslate()
  return (
    <LegacyDialog
      header={
        <TitleRow>
          <Avatar size="50" icon="circle-check-regular" color={colors.green[600]} />
          <Text variant="subtitle1">{translate('new_contract_signed')}</Text>
        </TitleRow>
      }
      onClose={onClose}
    >
      <Container>
        <Text variant="body2Medium">{translate('new_contract_signed_thank_you')}</Text>
        <Text variant="body2">{translate('new_contract_signed_explanation')}</Text>
        <FlexPushRight>
          <Button variant="primary" icon="arrow-right-regular" onClick={onClose}>
            {translate('continue')}
          </Button>
        </FlexPushRight>
      </Container>
    </LegacyDialog>
  )
})

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[40]};
`

const Container = styled(FlexSpacedColumn)`
  padding: ${spacing[40]} 0;
  gap: ${spacing[40]};
  padding-bottom: 0;
`

const FlexPushRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
